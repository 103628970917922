var GA4 = {
    init : function (){
        $('.js-product-item').on('click', function (e){
            e.preventDefault();
            let tempItem = JSON.parse($(this).attr('data-product'));
            let index = Number($(this).attr('data-index')) + 1;
            let url = $(this).attr('href');
            GA4.selectItem(tempItem, url, index);
        });
        $('#cta-button').on('click', function (e){
            e.preventDefault();
            let product = JSON.parse($(this).attr('data-product'));
            GA4.addToCart(product);
            $(this).submit();
        });
        $('#collections a').on('click', function (e){
            e.preventDefault();
            dataLayer.push({
                'event': 'view_promotion',
                'ecommerce': {
                    'items': [
                        {
                            'promotion_id': $(this).attr('promo-id'),
                            'promotion_name': $(this).attr('title'),
                            'creative_name': $(this).attr('title'),
                            'creative_slot': '',
                            'location_id': $(this).attr('position')
                        }      ]
                }
            });
            window.location.href = $(this).attr('href');
        });
    },
    viewItemList : function (itemsList, quantity){
        let items = this.makeItemList(itemsList, quantity);
        dataLayer.push({
            'event': 'view_item_list',
            'ecommerce': {items}
        });
    },
    makeItemList : function (itemsList, quantity) {
        let items = [];
        itemsList.forEach(function (i, index) {
            // console.log(i.id);
            item = {
                'item_name': i.name,
                'item_id': i.id,
                'price': i.price,
                'item_brand': i.producer,
                'item_category': i.parent_category_name,
                'item_category2': i.category_name,
                'item_variant': i.variant,
                'index': index,
                'item_list_name': i.item_list_name,
                'item_list_id': i.item_list_id,
                'quanity': quantity
            };
            items.push(item);
        });
        return items;
    },
    viewItem : function ( product ){
        dataLayer.push({
            'event': 'view_item',
            'ecommerce': {
                'items': {
                    'item_name': product.name,
                    'item_id': product.id,
                    'price': product.price,
                    'item_brand': product.producer,
                    'item_category': product.parent_category_name,
                    'item_category2': product.category_name,
                    'item_variant': product.variants,
                    'index': 0,
                    'item_list_name': '',
                    'item_list_id': '',
                    'quanity': 1
                }
            }
        });
    },
    beginCheckout : function (productsInCart){
        let items = this.makeCartItemList(productsInCart);
        dataLayer.push({
            'event': 'begin_checkout',
            'ecommerce': {items}
        });
    },
    makeCartItemList : function (products){
        let items = [];
        products.forEach(function (i, index){
            item = {
                'item_name': i.name,
                'item_id': i.id,
                'price': i.price,
                'item_brand': i.producer,
                'item_category': i.parent_category_name,
                'item_category2': i.category_name,
                'item_variant': i.variant,
                'quanity': i.quantity
            };
            items.push(item);
        });
        return items;
    },
    selectItem : function (tempItem, url, index ) {
        let items = this.prepareItem(tempItem, index);
        dataLayer.push({
            'event': 'select_item',
            'ecommerce': {items}
        });
        GA4.redirect(url);
    },
    prepareItem : function (tempItem, index) {
        let items = [];
        items = {
            'item_name': tempItem.name,
            'item_id':tempItem.id,
            'price': tempItem.price,
            'item_brand': tempItem.producer,
            'item_category': tempItem.parent_category_name,
            'item_category2': tempItem.category_name,
            'item_variant': tempItem.variant,
            'index': index,
            'item_list_name': tempItem.item_list_name,
            'item_list_id': tempItem.item_list_id,
            'quanity': 1
        };
        return items;
    },
    redirect : function (url){
        window.location.href = url;
    },
    addToCart : function (product) {
        let items = GA4.prepareProductToCart(product)
        dataLayer.push({
            'event': 'add_to_cart',
            'ecommerce': {items}
        });
    },
    prepareProductToCart : function (tempItem) {
        let items = [];
        items = {
            'item_name': tempItem.name,
            'item_id':tempItem.id,
            'price': tempItem.price,
            'item_brand': tempItem.producer,
            'item_category': tempItem.parent_category_name,
            'item_category2': tempItem.category_name,
            'item_variant': tempItem.variant,
            'index': 0,
            'item_list_name': tempItem.item_list_name,
            'item_list_id': tempItem.item_list_id,
            'quanity': 1
        };
        return items;
    },
    removeFromCartToGA4 : function (itemToRemove) {
        let items = GA4.prepareProductToRemove(itemToRemove);
        dataLayer.push({
            'event': 'remove_from_cart',
            'ecommerce': {items}
        });
    },
    prepareProductToRemove : function (tempItem) {
        let items = [];
        items = {
            'item_name': tempItem.name,
            'item_id':tempItem.id,
            'price': tempItem.price,
            'item_brand': tempItem.producer,
            'item_category': tempItem.parent_category_name,
            'item_category2': tempItem.category_name,
            'item_variant': tempItem.variant,
            'quanity': tempItem.quantity
        };
        return items;
    },
    addShippingInfo: function (products, shipmentMethod) {
        let items = GA4.makeCartItemList(products);
        dataLayer.push({
            'event': 'add_shipping_info',
            'ecommerce': {
                'shipping_tier': shipmentMethod,
                'items': items
            }
        });
    },
    addPaymentInfo: function (products, paymentMethod) {
        let items = GA4.makeCartItemList(products);
        dataLayer.push({
            'event': 'add_payment_info',
            'ecommerce': {
                'payment_type': paymentMethod,
                'items': items
            }
        });
    },
    getShippmentMethod : function (){
        let shipmentList = $('.choose-shipment-wrapper .ui-radio');
        let shipmentMethod = '';
        shipmentList.each(function () {
            if ($(this).hasClass('selected')) {
                shipmentMethod = $(this).find('.label').html();
            }
        });
        return shipmentMethod;
    },
    getPaymentMethod : function () {
        let paymentList = $('.choose-payment-wrapper .ui-radio');
        let paymentMethod = '';
        paymentList.each( function (){
            if($(this).hasClass('selected')){
                paymentMethod = $(this).find('.label').html();
            }
        });
        return paymentMethod;
    },
    purchaseToGA4 : function (products, purchaseData) {
        let items = GA4.makeCartItemList(products);
        dataLayer.push({
            'event': 'purchase',
            'ecommerce': {
                'currency' : 'PLN',
                'value' : purchaseData.totalPrice,
                'tax' : (purchaseData.totalPrice * 0.23),
                'shipping' : purchaseData.shippingCost,
                'transaction_id' : purchaseData.orderNr,
                'coupon' : '',
                'items' : items
            }
        });
    },
    removeFromCart : function (cartItems){
        $('.remove').on('click', function (e) {
            console.log('mam del');
            console.log(cartItems);
            e.preventDefault();
            e.stopPropagation();
            let url = $(this).attr('href');
            let itemToRemoveId = $(this).attr('data-remove');
            let itemToRemove = '';
            let quantity = $('#quantity-' + itemToRemoveId).val();
            cartItems.forEach(function (cartItem) {
                if (cartItem.id == String(itemToRemoveId)) {
                    itemToRemove = cartItem;
                    itemToRemove.quantity = quantity;
                }
            });
            GA4.removeFromCartToGA4(itemToRemove);
            GA4.redirect(url);
        });
    },
    addCheckoutInfo : function (GA4productsInCart){
        $('#cart-summary button').on('click', function (e) {
            e.preventDefault();
            let shipmentMethod = GA4.getShippmentMethod();
            GA4.addShippingInfo(GA4productsInCart, shipmentMethod);
            let paymentMethod = GA4.getPaymentMethod();
            GA4.addPaymentInfo(GA4productsInCart, paymentMethod);
            console.log('as');
            $('#cart-form').submit();
        });
    },
    purchase: function (GA4productsCheckout, orderData){
        GA4.purchaseToGA4(GA4productsCheckout, orderData);
    }
};